import axios from 'axios';

export async function get_profile_details(user_id){
   let res = await axios.get(`/backend/profile_detail/${user_id}/`, {});
        return res.data;
    }

export async function get_products(){
    let res = await axios.get('/backend/products/', {});
    return res.data;
}

export async function get_workorders() {
    let res = await axios.get('/backend/workorders/', {});
    return res.data;
}
export async function updateLineItem(pk, values) {
    let res = await axios.patch(`/backend/line_item/${pk}/`, values, {});
    return res;
}