import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, Spin } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import * as actions from "../store/actions/auth";
import { setRedirectCookie  } from '../utils/utils';

const cookies = new Cookies();

const { Option } = Select;

const OrgSetup = (props) => {
  let [org, setOrg] = useState();
  let [tenantId, setTenantId] = useState();
  let [tenants, setTenants] = useState();
  let [loading, setLoading] = useState(false);
  let [backendURL, setBackendURL] = useState();
  let history = useHistory();
  
  

  useEffect(() => {
    // TODO: CHECK THIS WORKS
    // console.log(`${axios.defaults.baseURL}`)
    // if(window.location.origin === "http://0.0.0.0:3000"){
    //   setBackendURL(`http://0.0.0.0:8000/backend/oauth/`);
    // }else{
    //   setBackendURL(`${window.location.origin}/backend/oauth/`)
    // }
    setRedirectCookie(`${window.location.href}`);
    // cookies.set(
    //   "oauth_redirect",
    //   `${window.location.href}`,
    //   { path: "/", expires: new Date(Date.now() + 2592000) }
    // );
    setOrg(cookies.get("org"));
    if (cookies.get("org")) {
      getTenants();
    }
  }, []);

  async function getTenants() {
    setLoading(true);
    axios
      .get(`/backend/tenants/`, {})
      .then((res) => {
        setTenants(res.data);
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("something went wrong");
        console.log(err);
        setLoading(false);
      });
  }
  function handleChange(value) {
    setTenantId(value);
  }
  const submit = () => {
    let _org = { name: org, tenant_id: tenantId };
    axios
      .post("/backend/organisations/", _org, {})
      .then((res) => {
        if (res.status == 201) {
          let user = {
            user: props.user_id,
            organisation: res.data.pk,
            is_admin: true,
          };
          axios
            .put(`/backend/profile_detail/${props.user_id}/`, user, {})
            .then(function (response) {
              props.getOrg();
            });
          // axios.put(`/backend/profile_detail/${props.user_id}/`, user, {})
          //   .then(function (response) {
          //       console.log(response);
          //   })
        }
      })
      .catch((err) => {
        //handle err empty response when backend is down
        if (err.response) {
          if (err.response.status === 403) {
            console.log(err.response);
          } else {
            console.log(err.response);
          }
        }
      });
  };
  function onFinish(values) {
    // let _org = { "name": org, "tenant_id": tenantId }
    console.log(values);
    cookies.set("org", values.org, {
      path: "/",
      expires: new Date(Date.now() + 2592000),
    });
    // axios.put(`/backend/organisations/${props.org_id}/`, _org, {})
    // history.push('/oauth');
    window.location.href = `${axios.defaults.baseURL}/backend/oauth`;
  }
  return (
    <>
      {/* <PageHeader title="Organisation Setup" /> */}
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      ) : (
        <>
          <h3>Add an organisation</h3>

          {org ? (
            <div>
              <Select style={{ width: 200 }} onChange={handleChange}>
                {tenants &&
                  tenants.map((tenant, index) => {
                    return (
                      <Option key={index} value={tenant.tenantId}>
                        {tenant.tenantName}
                      </Option>
                    );
                  })}
              </Select>

              <Button type="primary" onClick={submit}>
                Save
              </Button>
            </div>
          ) : (
            <Form onFinish={onFinish} requiredMark={"optional"}>
              <Form.Item
                required
                label="Name"
                name="org"
                rules={[
                  {
                    required: true,
                    message: "Please enter a name for your organisation!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user_id: state.user_id,
    org_failed: state.org_failed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrg: () => dispatch(actions.getOrg()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrgSetup);
