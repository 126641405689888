import React, { useState, useEffect } from "react";
import { Modal, Form, DatePicker, Input, InputNumber, Button, Space, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import {get_workorders} from '../utils/responses';


const EditLineItemForm = (props) => {
    
    const [workorders, setWorkorders] = useState([]);
    const [form] = Form.useForm();

    const { Option } = Select;
    useEffect(() => {
    get_workorders().then(data =>{
        setWorkorders(data.map(({ identifier, pk }) => ({
            label: identifier,
            value: pk,
            }))
        )
        })
    form.setFieldsValue({
              description: props.record.description,
              quantity: props.record.quantity,
              date: moment(new Date(props.record.date)),
              item_code: props.record.item_code,
              work_order: {value:props.record.work_order, label:props.record.workorder_name},
              pk: props.record.pk,
              price: props.record.price,

          })
    }, [props.record, setWorkorders]);

    return(
        <>
      <Modal
        visible={props.visible}
        title="Edit Item"
        okText="Save"
        cancelText="Cancel"
        onCancel={props.onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              props.onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          requiredMark={false}
        >
            <Form.Item
            hidden={true}
            name={"pk"}
            >
                <InputNumber/>
            </Form.Item>

            <Form.Item
            style={{minWidth:"200px"}}
            name={"item_code"}
            >

            <Select
                showSearch
                placeholder="Select a item"
                disabled={true}
                dropdownMatchSelectWidth={true}
            >
               
                <Option value={props.record.item_code}>
                    {props.record.itemcode_name}
                </Option>
                ))
            </Select>
            </Form.Item>

            <Form.Item
            label={'Quantity'}
            name={'quantity'}
            fieldKey={'quantity'}
            >
            <InputNumber placeholder={props.tab === "employee" ? "Hours" : "Quantity"} step={1.0} />
            </Form.Item>
            {props.record.price ?
            <Form.Item
                label={'Price'}
                name={'price'}
            >
                <InputNumber placeholder={"Cost per unit"} step={.1} />
            </Form.Item>
            : null}
            <Form.Item
            name={'description'}
            label={'Description'}
            rules={[{ required: true, message: 'Missing last name' }]}
            >
            <Input placeholder="Description" />
            </Form.Item>
            <Form.Item
            name={"work_order"}
            label={'Work Order'}
            fieldKey={'workorder'}
            rules={[
                {
                required: true,
                message: 'Please select a workorder',
                },
            ]}>

            <Select
                showSearch
                placeholder="Select a workorder"
                labelInValue={true}
            >
                {workorders.map(({ label, value }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
                ))}
            </Select>
            </Form.Item>

            <Form.Item
            name={"date"}
            fieldKey={"date"}
            >
            <DatePicker format="DD-MMM-YYYY" />
            </Form.Item>
        </Form>
      </Modal>
        </>
    );
}

  export default EditLineItemForm;