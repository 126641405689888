import React, { useState, useEffect } from "react";
import { Table, Button, Typography, notification, Tabs } from 'antd';
import { connect } from 'react-redux'
import axios from 'axios';
// import { setRedirectCookie  } from '../utils/utils';
import { openNotificationWithIcon, setRedirectCookie, checkOrg} from '../utils/utils';
import EditLineItemForm from './EditLineItemForm';
import {updateLineItem} from '../utils/responses';




const { Text } = Typography;

const ReviewLineItemList = (props) => {



  let [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let [loading, setLoading] = useState(false)
  let [materials, setMaterials] = useState()
  let [hours, setHours] = useState()
  let [custom, setCustom] = useState()
  let [columns, setColumns] = useState([])
  let [filteredInfo, setFilteredInfo] = useState({ "sent": ["false"] })
  let [customColumns, setCustomColumns] = useState([])
  let [formVisible, setFormVisible] = useState(false)
  let [editRecord, setEditRecord] = useState({})


  const { TabPane } = Tabs;
  
 

  useEffect(() => {
    console.log(props.describe_user_on_lineitem)
    async function getData() {
      if (props.token) {
        axios.get('/backend/line_items/', {})
          .then(res => {
            let data = res.data;
            let _materials = []
            let _hours = []
            let _custom = []
            for (let i = 0; i < data.length; i++) {
              data[i].key = data[i].pk
              data[i].sent = String(data[i].sent)
              if (data[i].item_type === "employee") {
                _hours.push(data[i])
              } else if (data[i].item_type === "material") {
                _materials.push(data[i])
              }
              else if (data[i].price) {
                _custom.push(data[i])
              }
            }
            setMaterials(_materials)
            setHours(_hours)
            setCustom(_custom)

            console.log(res.data)
            let _emp = [...new Set(_hours.map(x => x.itemcode_name))].map(y => ({ "text": y, "value": y }))
            let _mat = [...new Set(_materials.map(x => x.itemcode_name))].map(y => ({ "text": y, "value": y }))
            let _cust = [...new Set(_custom.map(x => x.itemcode_name))].map(y => ({ "text": y, "value": y }))
            let itemsFilter = [
              {text: 'Employees',
              value: 'Employees',
              children: _emp},
              {text: 'Materials',
              value: 'Materials',
              children: _mat},
              {text: 'Custom',
              value: 'Custom',
              children: _cust}
            ]
              

            let _columns = [
              {
                title: 'Item',
                dataIndex: 'itemcode_name',
                sorter: (a, b) => a.itemcode_name.length - b.itemcode_name.length,
                sortDirections: ['descend'],
                filters: itemsFilter,
                filteredValue: filteredInfo.itemcode_name || null,
                onFilter: (value, record) => record.itemcode_name.indexOf(value) === 0,
                
              },
              {
                title: "Description",
                render: (record) => (
                  <React.Fragment>
                    {props.describe_user_on_lineitem ? 
                    `${record.user_first_name} - ${record.description}`
                    : 
                    record.description
                    }
                  </React.Fragment>
                ),
              },
              // {
              //   title: 'Description',
              //   dataIndex: 'description',
              // },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
              },
              {
                title: 'Work Order',
                dataIndex: 'workorder_name',
                defaultSortOrder: 'descend',
                filters: [...new Set(res.data.map(x => x.workorder_name))].map(y => ({ "text": y, "value": y })),
                filteredValue: filteredInfo.workorder_name || null,
                // specify the condition of filtering result
                // here is that finding the name started with `value`
                onFilter: (value, record) => record.workorder_name.indexOf(value) === 0,
                sorter: (a, b) => a.workorder_name.length - b.workorder_name.length,
              },
              {
                title: 'Date',
                dataIndex: 'date',
                sorter: (a, b) => new Date(a.date) - new Date(b.date),
                sortDirections: ['descend', 'ascend'],
              },
              {
                title: 'Sent',
                dataIndex: 'sent',
                filters: [{ "text": "true", "value": "true" }, { "text": "false", "value": "false" }],
                // defaultFilteredValue: ["true"],
                filteredValue: filteredInfo.sent || null,
                filterMultiple: false,
                onFilter: (value, record) => record.sent.indexOf(value) === 0,
              },
            ];
            setColumns(_columns)
            let cost = {  title: 'Cost', dataIndex: 'price', responsive: ["xs","sm",],}
            let _customColumns = Array.from(_columns)
            _customColumns.splice(3, 0, cost)
            setCustomColumns(_customColumns)
          })
      }
    }

    getData()

  }, [props.token, filteredInfo, loading, selectedRowKeys, setSelectedRowKeys, setHours, setMaterials, props.describe_user_on_lineitem]);

  const onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys)
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters)
  }

  // const rowSelection = {
  //   getCheckboxProps: (record) => {
  //     return {
  //       disabled: record.sent === 'true' 
  //     };
  //   },
  //   onChange: onSelectChange,
  // };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  function start() {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 500);
  };

  function deleteLineItems(){
    let line_items = { "keys": selectedRowKeys }
    axios.post('/backend/line_items/delete/', line_items, {})
    .then(res=>{
      if (res.status == 200) {
        setCustom(custom.filter((i) => !selectedRowKeys.includes(i.pk)));
        setHours(hours.filter((i) => !selectedRowKeys.includes(i.pk)));
        setMaterials(materials.filter((i) => !selectedRowKeys.includes(i.pk)))
        openNotificationWithIcon('success', 'Items deleted!')
      }
    })
    .catch(err =>{
        openNotificationWithIcon('error', 'Failed to delete!')
        console.log(err)
    })
  }
  // const openNotificationWithIcon = (type, title, description) => {
  //   notification[type]({
  //     message: title,
  //     description: description,
  //     placement: 'bottomLeft',

  //   });
  // };

  function submit(){
      checkOrg(props.user_id).then((message)=>{
        console.log(message)
        sendLines()
    }).catch(error => {
      console.log(error)
      openNotificationWithIcon('error', error)
    })
  }
  function sendLines() {

    let line_items = { "keys": selectedRowKeys }
    setLoading(true)
    axios.post('/backend/line_items/submit/', line_items, {})
      .then(res => {
        if (res.status == 200) {
          openNotificationWithIcon('success', 'Items submitted successfully!')
          console.log(res);
          setLoading(false)
        for(let i of hours){
            if(selectedRowKeys.includes(i.pk)){
                i.sent = "true"
            }
        }
        for(let i of materials){
            if(selectedRowKeys.includes(i.pk)){
                i.sent = "true"
            }
        }
        for(let i of custom){
            if(selectedRowKeys.includes(i.pk)){
                i.sent = "true"
            }
        }
        setSelectedRowKeys([]);
        }
      })
      .catch(err => {
        //handle err empty response when backend is down
        if (err.response) {
          if (err.response.status === 417) {
            console.log(err.response)
            const data = JSON.parse(err.response.data);
            setSelectedRowKeys(data[0]['keys'])

            openNotificationWithIcon('error', 'The following errors occured!', "There are issues with the selected items. Contact an admin to fix these");
            setLoading(false)
          }
          else if (err.response.status === 403) {
            console.log(err.response)
            setRedirectCookie(window.location.href)
            setSelectedRowKeys([]);
            props.history.push("/oauth");
            
            // openNotificationWithIcon('error', 'Something went wrong!', 'There is an issue with the workorder for the items you are trying to submit.')
          }


          else {
            console.log(err.response)
            setSelectedRowKeys([]);
            setLoading(false)
            openNotificationWithIcon('error', 'Something went wrong!', 'Try again, if the the issue persists contact an admin.')
          }
        }
      })
      console.log(selectedRowKeys)
  };

    const onEdit = (values) => {
    // console.log(values.date.format("YYYY-MM-DD"));
    values.date = values.date.format('YYYY-MM-DD')
    values.work_order = values.work_order.value
    console.log('Received values of form: ', values);
    updateLineItem(values.pk, values).then(res=> {
      if(res.status === 200){
        openNotificationWithIcon('success', 'Item updated!')
    // if(item.pk == res.data.pk){
        // item.description = res.data.description
        let h = hours.findIndex(item => item.pk === res.data.pk)
        let c = custom.findIndex(item => item.pk === res.data.pk)
        let m = materials.findIndex(item => item.pk === res.data.pk)
        if(h != -1){
          let _hours = [...hours]
          _hours[h] = res.data
          _hours[h].key = _hours[h].pk
          _hours[h].sent = String(_hours[h].sent)
           setHours(_hours)
        }
        else if(c != -1){
          let _custom = [...custom]
          _custom[c] = res.data
          _custom[c].key = _custom[c].pk
          _custom[c].sent = String(_custom[c].sent)
          setCustom(_custom)
        }
        else if(m != -1){
          let _materials = [...materials]
          _materials[m] = res.data
          _materials[m].key = _materials[m].pk
          _materials[m].sent = String(_materials[m].sent)
          setMaterials(_materials)
        }
      }
    }).catch(err => {
        openNotificationWithIcon('error', 'Unable to update item')
    })
    setFormVisible(false);
  }
    const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        setEditRecord(record)
        setFormVisible(true)
      }
    };
  }
  const pageData = pageData => {
    let totalQuantity = 0;
    pageData.forEach(({ quantity }) => {
      totalQuantity += quantity;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong>Total hours</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong>{totalQuantity}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }
  const costSummary = pageData => {
    let totalQuantity = 0;
    pageData.forEach(({ price, quantity }) => {
      totalQuantity += price*quantity;
    });

    return (
      <>
      
        <Table.Summary.Row>
          <Table.Summary.Cell>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong>${totalQuantity}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }

  
  return (
    <>
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
            Reload
          </Button>
          <Button type="primary" onClick={submit} disabled={!hasSelected}>
            Submit
          </Button>
          <Button type="primary" onClick={deleteLineItems} disabled={!hasSelected}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
          <EditLineItemForm visible={formVisible} record={editRecord} onCreate={onEdit} onCancel={() => {
            setFormVisible(false);
          }}></EditLineItemForm>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Hours" key="1">
            <Table onRow={onRow} loading={loading} rowSelection={rowSelection} columns={columns} dataSource={hours} onChange={handleChange} summary={pageData} />

          </TabPane>
          <TabPane tab="Materials" key="2"> 
            <Table onRow={onRow} loading={loading} rowSelection={rowSelection} columns={columns} dataSource={materials} onChange={handleChange}/>
          </TabPane>
          {props.custom_items ? 
          <TabPane tab="Custom" key="3">
          <Table onRow={onRow} loading={loading} rowSelection={rowSelection} columns={customColumns} dataSource={custom} onChange={handleChange} summary={costSummary} />
          </TabPane>
           : null }
        </Tabs>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.token,
    custom_items: state.custom_items,
    user_id: state.user_id,
    describe_user_on_lineitem: state.describe_user_on_lineitem,
  }
}

export default connect(mapStateToProps)(ReviewLineItemList);
