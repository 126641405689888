import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs, Button, Space, Input } from 'antd';
import "antd/dist/antd.css";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import axios from 'axios';
import { openNotificationWithIcon} from '../utils/utils';
import EditLineItemForm from './EditLineItemForm';
import {updateLineItem} from '../utils/responses';



const { Text } = Typography;
const { TabPane } = Tabs;

const LineItemList = (props) => {



  let [loading, setLoading] = useState(false)
  let [materials, setMaterials] = useState()
  let [hours, setHours] = useState()
  let [custom, setCustom] = useState()
  let [columns, setColumns] = useState([])
  let [customColumns, setCustomColumns] = useState([])
  let [filteredInfo, setFilteredInfo] = useState({ "sent": ["false"] })
  let [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let [searchText, setSearchText] = useState('')
  let [searchedColumn, setSearchedColumn] = useState('')
  let [formVisible, setFormVisible] = useState(false)
  let [editRecord, setEditRecord] = useState({})


  useEffect(() => {
    async function getData() {
      if (props.token) {
        //add a thing to get by logged in user, unless admin
        axios.get('/backend/line_items/?review=0', {})
          .then(res => {
            let data = res.data;
            let _materials = []
            let _hours = []
            let _custom = []
            for (let i = 0; i < data.length; i++) {
              data[i].key = data[i].pk
              data[i].sent = String(data[i].sent)
              if (data[i].item_type === "employee") {
                _hours.push(data[i])
              } else if (data[i].item_type === "material") {
                _materials.push(data[i])
              }
              else if (data[i].price) {
                _custom.push(data[i])
              }
            }
            setMaterials(_materials)
            setHours(_hours)
            setCustom(_custom)

            let _columns = [
              {
                title: "Item",
                render: (record) => (
                  <React.Fragment>
                    {record.itemcode_name} - {record.description}
                    <br />
                    Workorder: {record.workorder_name}
                    <br />
                    Quantity: {record.quantity}
                    <br />
                    Date: {record.date}
                    <br />
                    {record.price ? 
                    <>Cost: ${record.price} </>
                    : null}
                    {/* Sent: {record.sent} */}
                  </React.Fragment>
                ),
                responsive: ["xs"],
              },
              {
                title: 'Item',
                dataIndex: 'itemcode_name',
                sorter: (a, b) => a.itemcode_name.length - b.itemcode_name.length,
                sortDirections: ['descend'],
                filters: [...new Set(_hours.map(x => x.itemcode_name))].map(y => ({ "text": y, "value": y })),
                filteredValue: filteredInfo.itemcode_name || null,
                onFilter: (value, record) => record.itemcode_name.indexOf(value) === 0,
                responsive: ["sm"],
              },
              {
                title: 'Description',
                dataIndex: 'description',
                responsive: ["sm"],
              },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
                responsive: ["sm"],
              },
              {
                title: 'Work Order',
                dataIndex: 'workorder_name',
                defaultSortOrder: 'descend',
                filters: [...new Set(res.data.map(x => x.workorder_name))].map(y => ({ "text": y, "value": y })),
                filteredValue: filteredInfo.workorder_name || null,
                // specify the condition of filtering result
                // here is that finding the name started with `value`
                onFilter: (value, record) => record.workorder_name.indexOf(value) === 0,
                sorter: (a, b) => a.workorder_name.length - b.workorder_name.length,
                responsive: ["sm"],
              },
              {
                title: 'Date',
                dataIndex: 'date',
                sorter: (a, b) => new Date(a.date) - new Date(b.date),
                sortDirections: ['descend', 'ascend'],
                responsive: ["sm"],
              },
              {
                title: 'Sent',
                dataIndex: 'sent',
                filters: [{ "text": "true", "value": "true" }, { "text": "false", "value": "false" }],
                // defaultFilteredValue: ["true"],
                filteredValue: filteredInfo.sent || null,
                filterMultiple: false,
                onFilter: (value, record) => record.sent.indexOf(value) === 0,
                responsive: ["xs","sm"],
              },
            ];
            setColumns(_columns)
            let cost = {  title: 'Cost', dataIndex: 'price', responsive: ["sm"],}
            let _customColumns = Array.from(_columns)
            _customColumns.splice(3, 0, cost)
            setCustomColumns(_customColumns)


          })
      }
    }
    getData()
  }, [props.token, filteredInfo, loading, setEditRecord,]);

  // useEffect(() => {
  // }, [setHours, setMaterials, setCustom]);

    const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  function deleteLineItems(){
    let line_items = { "keys": selectedRowKeys }
    axios.post('/backend/line_items/delete/', line_items, {})
    .then(res=>{
      if (res.status == 200) {
        setCustom(custom.filter((i) => !selectedRowKeys.includes(i.pk)));
        setHours(hours.filter((i) => !selectedRowKeys.includes(i.pk)));
        setMaterials(materials.filter((i) => !selectedRowKeys.includes(i.pk)))
        openNotificationWithIcon('success', 'Items deleted!')
      }
    })
    .catch(err =>{
        openNotificationWithIcon('error', 'Failed to delete!')
        console.log(err)
    })
  }

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters)
  }

  const pageData = pageData => {
    let totalQuantity = 0;
    pageData.forEach(({ quantity }) => {
      totalQuantity += quantity;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell>
            <Text strong>Total hours</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong>{totalQuantity}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }
  const costSummary = pageData => {
    let totalQuantity = 0;
    pageData.forEach(({ price, quantity }) => {
      totalQuantity += price*quantity;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong>${totalQuantity}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }

  const onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys)
  };
  // const rowSelection = 
  const hasSelected = selectedRowKeys.length > 0;

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        setEditRecord(record)
        setFormVisible(true)
      }
    };
  }
  const onEdit = (values) => {
    // console.log(values.date.format("YYYY-MM-DD"));
    values.date = values.date.format('YYYY-MM-DD')
    values.work_order = values.work_order.value
    console.log('Received values of form: ', values);
    updateLineItem(values.pk, values).then(res=> {
      if(res.status === 200){
        openNotificationWithIcon('success', 'Item updated!')
    // if(item.pk == res.data.pk){
        // item.description = res.data.description
        let h = hours.findIndex(item => item.pk === res.data.pk)
        let c = custom.findIndex(item => item.pk === res.data.pk)
        let m = materials.findIndex(item => item.pk === res.data.pk)
        if(h != -1){
          let _hours = [...hours]
          _hours[h] = res.data
          _hours[h].key = _hours[h].pk
          _hours[h].sent = String(_hours[h].sent)
           setHours(_hours)
        }
        else if(c != -1){
          let _custom = [...custom]
          _custom[c] = res.data
          _custom[c].key = _custom[c].pk
          _custom[c].sent = String(_custom[c].sent)
          setCustom(_custom)
        }
        else if(m != -1){
          let _materials = [...materials]
          _materials[m] = res.data
          _materials[m].key = _materials[m].pk
          _materials[m].sent = String(_materials[m].sent)
          setMaterials(_materials)
        }
      }
    }).catch(err => {
        openNotificationWithIcon('error', 'Unable to update item')
    })
    setFormVisible(false);
  }

  
  return (
    <>
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={deleteLineItems} disabled={!hasSelected}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Tabs defaultActiveKey="1">
          <EditLineItemForm visible={formVisible} record={editRecord} onCreate={onEdit} onCancel={() => {
            setFormVisible(false);
          }}></EditLineItemForm>
          <TabPane tab="Hours" key="1">
            <Table onRow={onRow} loading={loading} columns={columns} rowSelection={{selectedRowKeys, columnWidth:10, onChange: onSelectChange}} dataSource={hours} onChange={handleChange} summary={pageData} />
          </TabPane>
          <TabPane tab="Materials" key="2">
            <Table onRow={onRow} loading={loading} columns={columns} rowSelection={{selectedRowKeys, columnWidth:10, onChange: onSelectChange}} dataSource={materials} onChange={handleChange} />
          </TabPane>
          {props.custom_items ? 
          <TabPane tab="Custom" key="3">
            <Table onRow={onRow} loading={loading} columns={customColumns} rowSelection={{selectedRowKeys, columnWidth:10, onChange: onSelectChange}} dataSource={custom} onChange={handleChange} summary={costSummary} />
          </TabPane>
          : null}
        </Tabs>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.token,
    custom_items: state.custom_items,
  }
}

export default connect(mapStateToProps)(LineItemList);
