import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { Descriptions , Card, Form, Input, Button, Collapse} from 'antd';
import { EditOutlined, CheckOutlined} from '@ant-design/icons';
import { openNotificationWithIcon } from '../utils/utils';

// import { connect } from 'react-redux';
const { Panel } = Collapse;
const UserInfo = (props) => {
    let [userInfo, setUserInfo] = useState();
    let [edit, setEdit] = useState(false);

    const { Meta } = Card;
    const [form] = Form.useForm();
    const [detailsForm] = Form.useForm();


    useEffect(() => {
    if (props.token && props.user_id){
        getUserInfo();
    }
    }, [props.token])
    const getUserInfo = () => {
        axios.get(`/backend/profile_detail/${props.user_id}/`, {})
            .then(res => {
                console.log(res)
                setUserInfo(res.data)
            }).catch(err => {
                //handle err empty response when backend is down
                if (err.response) {
                    if (err.response.status === 403) {
                        console.log(err.response)
                        props.setRedirect(window.location.href)
                    }
                    else {
                        console.log(err.response)
                    }
                }
            })

    }

    const layout = {
        labelCol: { span: 2 },
        wrapperCol: { span: 10 },
      };
    const changePassword = (values) => {
        console.log(values);
        axios.post(`/rest-auth/password/change/`, values, {})
            .then(function (response) {
                console.log(response);
                openNotificationWithIcon('success', 'Password Changed')
            })
      };

    const editDetails = (values) => {
    axios.put(`/backend/user/${props.user_id}/`, values, {})
        .then(function (response) {
            console.log(response);
            setEdit(false)
            openNotificationWithIcon('success', 'Details saved')

        })
    };

    const getInitialValues = () => {
    let initialValues = {}
    if(userInfo){
        initialValues.remember = localStorage.getItem("remember");
        initialValues.first_name = userInfo[0].first_name
        initialValues.last_name = userInfo[0].last_name
        initialValues.email = userInfo[0].email
        initialValues.username = userInfo[0].username
    }
    return initialValues
    }
    return (
        <>
            {edit ? 
            <>
        <Card
            title="User Info"
            actions={[
                <Button shape="circle" onClick={() => {
                    detailsForm.validateFields().then(detailsForm.submit())
                }}><CheckOutlined /></Button>,
            ]}>
            <Form
            form={detailsForm}
            name="basic"
            initialValues={getInitialValues()}
            onFinish={editDetails}
            // onFinishFailed={onFinishFailed}
            >

            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >

                <Input disabled={true} />
            </Form.Item>
            <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'Please input your first name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: 'Please input your last name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input an email!' }]}
            >
                <Input />
            </Form.Item>
            </Form>
        </Card>
        </>
            :
            <Card
            title="User Info"
            actions={[
                <Button shape="circle" onClick={() => setEdit(!edit)}><EditOutlined /></Button>,
            ]}>
            <Descriptions size="default" column={1}>

                {userInfo ? <>
                <Descriptions.Item label="Username">{userInfo[0].username}</Descriptions.Item>
                <Descriptions.Item label="Name">{userInfo[0].first_name ? userInfo[0].first_name : "Not set"}</Descriptions.Item>
                <Descriptions.Item label="Surname">{userInfo[0].last_name ? userInfo[0].last_name : "Not set"}</Descriptions.Item>
                <Descriptions.Item label="Email">{userInfo[0].email ? userInfo[0].email : "Not set"}</Descriptions.Item>
                <Descriptions.Item label="Organisation">{userInfo[0].org_name ? userInfo[0].org_name : "Not set"}</Descriptions.Item>
                </>
                : 
                <Descriptions.Item label="Error">Problem loading user info</Descriptions.Item>
                 }
            </Descriptions>
        </Card>

        }   

  <Collapse >
    <Panel header="Change Password" key="1">

        <Form {...layout} form={form}  onFinish={changePassword}>
        <Form.Item name="old_password" label="Old Password" rules={[{ required: true }]}>
            <Input.Password />
        </Form.Item>
        <Form.Item name="new_password1" label="New Password" rules={[{ required: true }]}>
            <Input.Password />
        </Form.Item>
        <Form.Item name="new_password2" label="Confirm Password" rules={[{ required: true }]}>
            <Input.Password />
        </Form.Item>
        <Form.Item style={{textAlignLast: "center"}}>
            <Button type="primary" htmlType="submit">
            Change Password
            </Button>
        </Form.Item>
        </Form>
    </Panel>
  </Collapse>

        </>
    )
}


const mapStateToProps = state => {
    return {
        token: state.token,
        org: state.org,
        org_id: state.org_id,
        user_id: state.user_id
    }
}
export default connect(mapStateToProps)(UserInfo);