import { Transfer, Button } from 'antd';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { openNotificationWithIcon } from '../utils/utils';

const SearchTransfer = (props) => {      

  let [targetKeys, setTargetKeys] = useState([])
  let [selectedKeys, setSelectedKeys] = useState([])
  let [data, setData] = useState([])

  function filterByItemType(item) {
  if(item.item_type === "employee"){
    return false;
  }
  if (props.item_type === "custom" && item.item_type !== "material") {
    return true;
  }
  else if (props.item_type === "material" && item.custom === false){
    return true;
  }
  return false;
}
  useEffect(() => {
    // console.log(props.data)
    let _targetKeys = []
    setData(props.data.filter(filterByItemType).map(i=>{
        let item = {}
        item.key = i.pk.toString();
        item.title = i.name;
        item.description = i.name;
        if(props.item_type === "custom" && i.custom){
          _targetKeys.push(item.key)
        }
        else if(i.item_type===props.item_type){
          _targetKeys.push(item.key)
        }
        return item;
    }))
    setTargetKeys(_targetKeys)
  }, [props.data, setData]);

  const handleChange = (targetKeys) => {
    setTargetKeys(targetKeys);
  };
  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys(Array.from(sourceSelectedKeys));
    setTargetKeys([...targetSelectedKeys, ...Array.from(targetKeys)]);
  };

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  
  const onSave = () => {
    console.log(targetKeys)
    let keys = { 'keys': targetKeys}
    axios.post(`/backend/${props.item_type}/`, keys, {})
        .then(res => {openNotificationWithIcon('success', 'Items Saved')})
        .catch(err => {
            //handle err empty response when backend is down
            if (err.response) {
                if (err.response.status === 403) {
                    console.log(err.response)
                }
                else {
                    console.log(err.response)
                }
            }
        })
  };

    return (
      <>  
        <Transfer
            dataSource={data}
            showSearch
            filterOption={filterOption}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={handleChange}
            onSelectChange={handleSelectChange}
            // onSearch={this.handleSearch}
            render={item => item.title}
            listStyle={{
              width: 300,
              height: 500,
              }}
            titles={['Unassigned', `${props.item_type.charAt(0).toUpperCase() + props.item_type.slice(1)}`]}
            oneWay
        />
      <Button type="primary" onClick={onSave}>Save</Button>
      </>

    )
}

export default SearchTransfer;
