import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import reducer from './store/reducers/auth';

// axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASEURL;
if (window.location.origin === "http://localhost:3000") {
    axios.defaults.baseURL = "http://localhost:8000";
} else {
    axios.defaults.baseURL = window.location.origin;
}
axios.defaults.withCredentials = true;

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhances(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
