import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { get_profile_details } from './responses';
import { notification } from 'antd';


const cookies = new Cookies();

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const setRedirectCookie = (redirect_url) => {
    cookies.set('oauth_redirect', redirect_url, { path: '/', maxAge: 31536000});
}
export const setOrgCookie = (org_name) => {
    cookies.set('org', org_name, { path: '/', maxAge: 31536000});
}

// export const checkOrg = (user_id) => {
//   let org = cookies.get('org');
//   if(org){
//     return true
//   }else{
//     let result = get_profile_details(user_id).then(data => {
//       if(data.length !== 0 && 'organisation' in data[0]){
//           setOrgCookie(data[0].org_name)
//           return true
//       }else{
//         return false
//       }
//     })
//     return result
//     // return false
//   }
// }
export const checkOrg = (user_id) => {
  return new Promise((resolve, reject) => {
    let org = cookies.get('org');
    if(!user_id){
      reject("Error: User not found")
    }
    if(org){
      resolve("Org found")
    }else{
      let result = get_profile_details(user_id).then(data => {
        if(data.length !== 0 && 'organisation' in data[0]){
            setOrgCookie(data[0].org_name)
            return true
        }else{
          return false
        }
      }).then(result => {
        if(result){
          resolve("Renewed org")
        }else{
          reject("Unable to renew org")
        }
      })
      .catch(error => {
        console.log(error)
        reject("Error: Theres a problem with the server")
      })
    }
  })

}

 export const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
      placement: 'bottomLeft',

    });
  };