import React, { useState, useEffect } from "react";
import { Button, Layout, Menu, Collapse } from 'antd';
import {
  ToolOutlined,
  FileAddOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import OrgSetup from '../containers/OrgSetup';
import '../App.css';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;


const CustomLayout = (props) => {
  let [isBroken, setIsBroken] = useState();
  let [collapsed, setCollapsed] = useState();
  useEffect(() => {
    }, [isBroken, collapsed]);

  const logout = () => {
    props.logout()
    props.history.push("/login");
  }

  const textAlign = {textAlign: 'center'}



return(
  <Layout>
          <Header>
            <div className="logo" />
            <Menu
              theme="dark"
              mode="horizontal"
              // defaultSelectedKeys={['2']}
              style={{ lineHeight: '64px' }}
            >
              {
                props.isAuthenticated ?
                  <>

                  {isBroken ?  
                  <>
                    <Menu.Item key="2" style={{ float: 'right', padding: '2px 2px 2px 2px'}} onClick={logout}>Logout</Menu.Item>
                    <SubMenu key="sub4" style={{ float: 'right',padding: '2px 10px' }} icon={<MenuOutlined />} title={""}>
                      <Menu.Item key="setting:1"><Link to="/settings/userinfo/">User Info</Link></Menu.Item>
                      {props.org ?
                        <>
                          <Menu.Item key="setting:3"><Link to="/settings/manageusers/">Manage Users</Link></Menu.Item>
                          <Menu.Item key="setting:4"><Link to="/settings/organisation/">Settings</Link></Menu.Item>
                          {/* <Menu.Item key="setting:5"><Link to="/settings/orgsetup/">Setup</Link></Menu.Item> */}
                        </>
                        :
                        null
                      }
                    </SubMenu>
                    {/* <SubMenu key="sub4" style={{ float: 'right' }} icon={<MenuOutlined />} title={}> */}
                    {/* </SubMenu> */}
                    </>
                  : 
                    <>
                    <Menu.Item key="2" style={{ float: 'right' }} onClick={logout}>
                      Logout
                    </Menu.Item>
                    <SubMenu key="sub4" style={{ float: 'right' }} icon={<MenuOutlined />} title={""}>
                      <Menu.Item key="setting:1"><Link to="/settings/userinfo/">User Info</Link></Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub3" style={{ float: 'right' }} title={props.org ? props.org : "Org Setup Required"}>
                      {props.org && props.is_admin ?
                        <Menu.ItemGroup title="Organisation">
                          <Menu.Item key="setting:3"><Link to="/settings/manageusers/">Manage Users</Link></Menu.Item>
                          <Menu.Item key="setting:4"><Link to="/settings/organisation/">Settings</Link></Menu.Item>
                          {/* <Menu.Item key="setting:5"><Link to="/settings/orgsetup/">Setup</Link></Menu.Item> */}
                        </Menu.ItemGroup>
                        :
                        null
                      }
                    </SubMenu>
                    </>
                  }
                  </>
                  :
                  <Menu.Item style={{ float: 'right' }} key="2">
                    <Link to="/login">Login</Link>
                  </Menu.Item>
              }
            </Menu>
          </Header>
    <Layout>
      {
          props.isAuthenticated ?
      <Sider width={175} className="site-layout-background" breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
              setIsBroken(broken)
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed)
            }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
        >
          <SubMenu key="sub1" icon={<FileAddOutlined />} disabled={props.org_failed} title="Timesheets">
            <Menu.Item key="3"><Link to="/timesheet/create/" props={props}>Create</Link></Menu.Item>
            <Menu.Item key="4"><Link to="/timesheet/list/">View</Link></Menu.Item>
            {props.is_admin ? 
            <Menu.Item key="5"><Link to="/timesheet/review/">Review</Link></Menu.Item>
            : null }
          </SubMenu>
          <Menu.Item key="8" icon={<ToolOutlined />} disabled={props.org_failed}><Link to="/workorder/list/">Workorders</Link></Menu.Item>
        </Menu>
      </Sider>
      : <></>}
      <Layout style={{ padding: '0 5px 5px' }}>
        <Content
          className="site-layout-background"
          style={{
            padding: 5,
            margin: 0,
            minHeight: "85vh",
          }}
        >
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              
              {props.isAuthenticated && props.org_failed ?
  
              <div style={{margin: 'auto', width: '50%' }}>
                <div>
                  <h2 style={textAlign}>You don't have an organisation yet!</h2>
                  <h4 style={textAlign}>Get in contact with your employer to add you to their organisation</h4>
                  <br/>
  
              <Collapse accordion>
                <Panel showArrow={false} header="If you are an employer click here to set up your organisation" key="1">
                  <OrgSetup></OrgSetup>
                </Panel>
  
              </Collapse>
                      
                </div>
              </div>
              : props.children}
              </div>
            </Content>
      </Layout>
    </Layout>
    <Footer style={textAlign}>Powered by technikiwi</Footer>
  </Layout>
);
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout()),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(CustomLayout));
