import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import LineItemForm from '../components/LineItemForm';
import SetCustom from "./SetCustom";





const ManageUsers = (props) => {

    const { TabPane } = Tabs;
    const [tab, setTab] = useState("employee");
    // const [loading, setLoading] = useState({"workorders": true, "products": true});
    const [workorders, setWorkorders] = useState([{ label: "Loading ...", value: "" }]);
    const [materials, setMaterials] = useState([{ label: "Loading ...", value: "" }]);
    const [itemCodes, setItemCodes] = useState([{ label: "Loading ...", value: "" }]);
    const [employees, setEmployees] = useState([{ label: "Loading ...", value: "" }]);
    const [custom, setCustom] = useState([{ label: "Loading ...", value: "" }]);

    async function getWorkorders() {
        if (props.token) {
            axios.get('/backend/workorders/', {}).then(res => {
                setWorkorders(res.data.map(({ identifier, pk }) => ({
                    label: identifier,
                    value: pk,
                })))
            }
            )
        }
    }
    async function getProducts() {
        if (props.token) {
            axios.get('/backend/products/', {}).then(res => {
                if (res.data) {
                    let _employees = []
                    let _materials = []
                    let _custom = []
                    res.data.forEach(({ name, pk, item_type, custom }) => {
                        if (item_type === "employee") {
                            _employees.push({ label: name, value: pk });
                        }
                        else if (item_type === "material") {
                            _materials.push({ label: name, value: pk });
                        }
                        else if (custom === true){
                            _custom.push({ label: name, value: pk })
                        }
                    }
                    )
                    setMaterials(_materials);
                    setEmployees(_employees);
                    setCustom(_custom);
                }
            }
            )
        }
    }
    async function getUserItemCodes() {
        if (props.token) {
            axios.get('/backend/itemcodes/', {}).then(res => {
                if (res.data) {
                    //label is the itemcode, and the value is the pk of the product that the user is assigned to use
                    let _itemCodes = res.data.filter(i => i.user_id == props.user_id).map(i => { return {label: i.item_code, value: i.product} })
                    setItemCodes(_itemCodes);
                }
            }
            )
        }
    }

    useEffect(() => {
        getWorkorders();
        getProducts();
        getUserItemCodes()

    }, [tab, props.token]);

    const callback = (key) => {
        switch (key) {
            case "1": {
                setTab("employee")
                break;
            }
            case "2": {
                setTab("material")
                break;
            }
            case "3": {
                setTab("custom")
                break;
            }
            default: {
                setTab("employee")
                break;
            }
        }
    }

    return (
        <>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Hours" key="1">
                    <LineItemForm tab={tab} workorders={workorders} products={props.is_admin ? employees : itemCodes} />
                </TabPane>
                <TabPane tab="Materials" key="2">
                    <LineItemForm tab={tab} workorders={workorders} products={materials} />

                </TabPane>
                {props.custom_items ? 
                <TabPane tab="Custom" key="3">
                    <LineItemForm tab={tab} workorders={workorders} products={custom} />
                </TabPane>
                : null }
            </Tabs>
        </>
    )

}
const mapStateToProps = state => {
    return {
        org_id: state.org_id,
        token: state.token,
        is_admin: state.is_admin,
        user_id: state.user_id,
        custom_items: state.custom_items,
    }
}

export default connect(mapStateToProps)(ManageUsers);