import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const NormalLoginForm = (props) => {

  useEffect(() => {

    if(props.error){
      message.error("Password is incorrect or username doesn't exist");
    }
    }, [props.error, props.isAuthenticated])

  const getInitialValues = () => {
    let initialValues = {}
    initialValues.remember = localStorage.getItem("remember");
    if (initialValues.remember === "true") {
      initialValues.username = localStorage.getItem("username");
      initialValues.password = localStorage.getItem("password");
    }
    return initialValues
  }

  const onFinish = (values) => {
    if (values.remember) {
      localStorage.setItem("remember", true);
    } else {
      localStorage.removeItem("remember");
    }
    props.onAuth(values.username, values.password)

  };

  return (
    <>
      { props.isAuthenticated ?
      <Redirect to={'/timesheet/create/'} />
      :
        <Form
          name="normal_login"
          className="login-form"
          initialValues={getInitialValues()}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {({ errors }) => (
          <Form.ErrorList errors={errors} />
          )}
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            {/* <a className="login-form-forgot" href="">Forgot password</a> */}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
        </Button>
         Or <Link to="/signup/">Sign up!</Link>
          </Form.Item>
        </Form>
  }
    </>

  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    isAuthenticated: state.token !== null,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);
