import React, { useState, useEffect } from "react";
import { Statistic, Form, Button, Checkbox, Input, Card, Row, Col, Radio, notification} from 'antd';
import { SettingTwoTone  } from '@ant-design/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import * as actions from '../store/actions/auth';
import { setRedirectCookie, checkOrg, setOrgCookie  } from '../utils/utils';
import { resolveOnChange } from "antd/lib/input/Input";



const OrgSettings = (props) => {

    const { Meta } = Card;
    const [form] = Form.useForm();
    let [emails, setEmails] = useState();
    let [isLoading, setIsLoading] = useState(false);
    let [contacts, setContacts] = useState();
    let [products, setProducts] = useState();

    useEffect(() => {
        
        async function getSettings() {
            axios.get('/backend/organisations/settings/', {})
                .then(res => {
                    console.log(res)
                    let _settings = []
                    let _email = ""
                    let _invoice_status = res.data.invoice_status
                    let _describe_user_on_lineitem = ""
                    if (res.data.fwd_email) {
                        _email = res.data.fwd_email
                    }
                    if (res.data.describe_invoice) {
                        _settings.push("describe_invoice")
                    }                    
                    if (res.data.custom_items) {
                        _settings.push("custom_items")
                    }
                    if(res.data.describe_user_on_lineitem){
                        _settings.push("describe_user_on_lineitem")
                    }
                    form.setFieldsValue({
                        "checkbox-group": _settings,
                        "email": _email,
                        "invoice_status": _invoice_status,
                        "describe_user_on_lineitem": _describe_user_on_lineitem,
                    });
                })
        }
        getSettings()
    }, [form]);
  
    const openNotificationWithIcon = (type, title, description) => {
        notification[type]({
            message: title,
            description: description,
            placement: 'bottomLeft',

        });
    };
    const onFinish = values => {
        let settings = values
        // console.log(values)
        axios.post('/backend/organisations/settings/', settings, {})
        .then(res => {
            openNotificationWithIcon('success', 'Settings updated')
            props.getOrg();
        })
        .catch(err => {
            openNotificationWithIcon('error', 'Updating settings failed. Try again and if issue persists contact administrator')
        })


    };

    const options = [
        { label: 'Describe Invoice on first line of invoice', value: 'describe_invoice' },
        { label: 'Allow custom items', value: 'custom_items' },
        { label: 'Describe user on lineitem', value: 'describe_user_on_lineitem' },
    ];

    const testEmail = () => {
        setIsLoading(true)
        axios.get(`/backend/mail/test`, {})
            .then(res => {
                setEmails(res.data.count)
                setIsLoading(false)
            })
    }
    const processEmail = () => {
        axios.get(`/backend/mail/process`, {})
            .then(res => {
                console.log(res)
            })
    }

    const getContacts = () => {
        setIsLoading(true)
        axios.get(`/backend/contacts/update/`, {})
            .then(res => {
                console.log(res)
                setContacts(res.data.Contacts)

            }).catch(err => {
                //handle err empty response when backend is down
                if (err.response) {
                    if (err.response.status === 403) {
                        console.log(err.response)
                        setRedirectCookie(window.location.href)
                        props.history.push("/oauth");

                    }
                    else {
                        console.log(err.response)
                    }
                }
            })
        setIsLoading(false)
    }
    const createContact = (name) => {
        axios.post('/backend/contacts/create/', name, {})
        .then(res => {
            if (res.status === 200){
                openNotificationWithIcon('success', 'Contact Added')
            }
            if (res.status === 204){
                openNotificationWithIcon('warning', 'Contact already exists')
            }

        })
        .catch(err => {
            openNotificationWithIcon('error', 'Failed to add contact, refresh and try again. If problem persist contact admin')
        })
    }
    const pullContacts = () => {

        checkOrg(props.user_id).then((message)=>{
            console.log(message)
            getContacts()
        }).catch(error => {
            console.log(error)
        })
    }

    const pullProducts = () => {
        checkOrg(props.user_id).then((message)=>{
            console.log(message)
            getProducts()
        }).catch(error => {
            console.log(error)
        })
    }
    const getProducts = () => {
        setIsLoading(true)
        axios.get(`/backend/products/update/`, {})
            .then(res => {
                console.log(res)
                setProducts(res.data.Items)

            }).catch(err => {
                //handle err empty response when backend is down
                if (err.response) {
                    if (err.response.status === 403) {
                        console.log(err.response)
                        setRedirectCookie(window.location.href)
                        props.history.push("/oauth");


                    }
                    else {
                        console.log(err.response)
                    }
                }
            })
        setIsLoading(false)

    }
    return (
        <>
                <Row gutter={16}>
                    <Col span={16}>
                        <Card title="Organisation Settings" bordered={false}>
                            {/* <PageHeader title="Organisation Settings" /> */}
                            <Form
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 14 }}
                                layout="vertical"
                                size={'large'}
                                onFinish={onFinish}
                                form={form}
                            >
                                <Form.Item name="checkbox-group">
                                    <Checkbox.Group options={options}>
                                    </Checkbox.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Forwarding Email"
                                    tooltip="Enter the email you will send PO's from. Please set up a fowarding rule from this email to send all PO's to forward@technikiwi.com"
                                    name="email"
                                    rules={[{ required: true, type: "email" }]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item label="Invoice Status" name="invoice_status">
                                    <Radio.Group>
                                    <Radio.Button value="DRAFT">Draft</Radio.Button>
                                    <Radio.Button value="AUTHORISED">Authorised</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ width: 300 }}
                            actions={[
                                <SettingTwoTone key="setting" spin={isLoading} onClick={testEmail} />,
                            ]}
                        >
                            <Meta
                                title="Test Email"
                            />



                            <Statistic title="Emails" value={emails} Loading={isLoading} />
                            {emails ? "Your email is set up!" : "Click Below to test your email"}
                            <Button type="secondary" onClick={processEmail}>Process PO</Button>
                        </Card>
                        <Card style={{ width: 300 }}
                            actions={[
                                <SettingTwoTone key="contacts" spin={isLoading} onClick={pullContacts} />,
                            ]}
                        >
                            <Meta
                                title="Contacts"
                            />
                            {contacts ? contacts.map(c => {
                                return (
                                    <p>{c.Name}</p>
                                )
                            }) : "none"}
                        </Card>
                        <Card style={{ width: 300 }}
                            actions={[
                                <SettingTwoTone key="products" spin={isLoading} onClick={pullProducts} />,
                            ]}
                        >
                            <Meta
                                title="Products"
                            />
                            {products ? products.map(p => {
                                return (
                                    <p>{p.Name}</p>
                                )
                            }) : "none"}
                        </Card>
                        <Card title="Add Contact" bordered={false}>
                            <Form
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 14 }}
                                layout="vertical"
                                size={'large'}
                                onFinish={createContact}
                            >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
        </>
    );
};

const mapStateToProps = state => {
    return {
        token: state.token,
        org: state.org,
        org_id: state.org_id,
        user_id: state.user_id,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    getOrg: () => dispatch(actions.getOrg()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrgSettings);