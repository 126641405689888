import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Row, Col, Select, Tabs, Button, Card, Divider } from 'antd';
import { connect } from 'react-redux';
import AddUser from '../components/AddUser';
import AdminUsers from '../components/AdminUsers';
import SetMaterials from '../containers/SetMaterials';
import SearchTransfer from '../components/SearchTransfer';



const { Option } = Select;
const { TabPane } = Tabs;

const ManageUsers = (props) => {


    let [users, setUsers] = useState([]);
    let [itemCodes, setItemCodes] = useState([]);
    let [userItemCodes, setUserItemCodes] = useState([]);
    let [activeKey, setActiveKey] = useState();


    function handleChange(values, keys) {
        setUserItemCodes(keys);
    }

    function onTabChange(pk) {
        setActiveKey(pk)
        getUserItemCodes(pk);
    }
    function onSubmit() {
        //#TODO submit this to api  
        axios.post(`/backend/user_itemcodes/${activeKey}/`, {
            "user": activeKey,
            "item_codes": userItemCodes.map(item => (item.key)),
        }, {})
            .then(function (response) {
                console.log(response);
            })
    }

    async function getUserItemCodes(pk) {
        axios.get(`/backend/itemcodes/?user=${pk}`, {})
            .then(res => {
                let user_items = res.data.map(item => ({ 'key': item.product, 'value': item.item_code }))
                setUserItemCodes(user_items)
            })
            .catch(err => {
                if (err.response.status === 404) {
                    // client received an error response (5xx, 4xx)
                    setUserItemCodes([]);
                    console.log("No user items");
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Unable to connect");
                } else {
                    // anything else
                    console.log("Something bad happened: " + err)
                }
            })
    }

    useEffect(() => {
        async function getUsers() {
            axios.get(`/backend/profile_detail/`, {})
                .then(res => {
                    setActiveKey(res.data[0].user)
                    getUserItemCodes(res.data[0].user)
                    setUsers(res.data)
                })
        }
        async function getItemCodes() {
            axios.get("/backend/products/", {})
                .then(res => {
                    setItemCodes(res.data)//filter(i => i.item_type !== "material"))
                })
        }
        if (props.org_id) {
            getUsers();
            getItemCodes();
        }
    }, [props.token, props.org_id]);

    return (
        <>
            <Row gutter={16}>

                <Col span={8}>
                    <Card title="User Management" bordered={false}>
                        <AddUser org_id={props.org_id} token={props.token}></AddUser>
                        <Divider />
                        <AdminUsers key={users} org_id={props.org_id} token={props.token} users={users}></AdminUsers>

                    </Card>
                </Col>
                <Col span={16}>
                    <Card title="Set Materials" bordered={false}>
                        {/* <SetMaterials products={itemCodes} token={props.token} /> */}
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Materials" key="1">
                                <SearchTransfer data={itemCodes} token={props.token} item_type="material" />
                            </TabPane>
                            <TabPane tab="Custom" key="2">
                                <SearchTransfer data={itemCodes} token={props.token} item_type="custom" />
                            </TabPane>
                        </Tabs>
                    </Card>
                    <Card title="Set users itemcodes" bordered={false}>
                        <Tabs defaultActiveKey={activeKey} tabPosition='left' onChange={onTabChange}>
                            {(users).map(i => (
                                <TabPane tab={i.username} key={i.user}>
                                    Item codes:
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="select itemcodes"
                                        allowClear
                                        value={userItemCodes.map(i => (i.value))}
                                        onChange={handleChange}
                                        optionLabelProp="label"
                                    >
                                        {itemCodes &&
                                            [...Array.from(itemCodes)].filter(i => i.item_type !== "material").map(itemCode => {
                                                return (
                                                    <>
                                                        <Option key={itemCode.pk} value={itemCode.name} label={itemCode.name}>
                                                            <div>
                                                                {itemCode.name}
                                                            </div>
                                                        </Option>
                                                    </>
                                                )
                                            })}
                                    </Select>
                                    <Button type="primary" size="small" style={{ margin: "3px" }} onClick={onSubmit}>Save</Button>
                                </TabPane>
                            ))}
                        </Tabs>
                    </Card>
                </Col>
            </Row>


        </>
    )

}
const mapStateToProps = state => {
    return {
        org_id: state.org_id,
        token: state.token,
    }
}

export default connect(mapStateToProps)(ManageUsers);