import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Select, Tabs, Button } from 'antd';
import { connect } from 'react-redux';

const { Option } = Select;
const { TabPane } = Tabs;

const SetMaterials = (props) => {

    let [products, setProducts] = useState([]);
    let [materials, setMaterials] = useState([]);
    let [custom, setCustom] = useState([]);
    let [token, setToken] = useState(props.token)

    useEffect(() => {
        if (props.products) {
            let _products = []
            let _materials = []
            let _custom = []
            props.products.forEach((product) => {
                let item = { 'key': product.pk, 'value': product.name, 'item_type': product.item_type }
                if (product.item_type != "employee") {
                    _products.push(item)
                }
                if (product.item_type === "material") {
                    _materials.push(item)
                }
                if (product.custom === true) {
                    _custom.push(item)
                }

            });
            setProducts(_products)
            setMaterials(_materials)
            setCustom(_custom)
            setToken(props.token);
        }
    }, [props.products, props.token]);


    function onSubmitMaterials() {
        console.log(token);
        // console.log(materials.map(item => item.key));
        let keys = { 'keys': materials.map(item => item.key) }
        axios.post('/backend/materials/', keys, {})
            .then(res => {
                if (res.status == 200) {
                    console.log(res);
                }
            })
            .catch(err => {
                //handle err empty response when backend is down
                if (err.response) {
                    if (err.response.status === 403) {
                        console.log(err.response)
                    }
                    else {
                        console.log(err.response)
                    }
                }
            })
    }
    function onSubmitCustom() {
        console.log(token);
        // console.log(materials.map(item => item.key));
        let keys = { 'keys': custom.map(item => item.key) }
        axios.post('/backend/custom_items/', keys, {})
            .then(res => {
                if (res.status == 200) {
                    console.log(res);
                }
            })
            .catch(err => {
                //handle err empty response when backend is down
                if (err.response) {
                    if (err.response.status === 403) {
                        console.log(err.response)
                    }
                    else {
                        console.log(err.response)
                    }
                }
            })
    }

    function handleChangeMaterial(values, keys) {
        setMaterials(keys);
    }
    function handleChangeCustom(values, keys) {
        setCustom(keys);
    }
    const selectMaterials = () => {
        return (
            <>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="select itemcodes"
                    allowClear
                    value={materials.length > 0 ? materials.map(i => i.value) : []}
                    onChange={handleChangeMaterial}
                    optionLabelProp="label"
                >
                    {products.length > 0 &&
                        products.map(product => {
                            return (
                                <>
                                    <Option key={product.key} value={product.value} label={product.value}>
                                        <div>
                                            {product.value}
                                        </div>
                                    </Option>
                                </>
                            )
                        })}
                </Select>
                {/* {products.length > 0 && console.log(products.map(product => product.name))} */}

                <Button type="primary" size="small" style={{ margin: "3px" }} onClick={onSubmitMaterials}>Save</Button>
            </>
        )
    }
    const selectCustom = () => {
        return (
            <>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="select itemcodes"
                    allowClear
                    value={custom.length > 0 ? custom.map(i => i.value) : []}
                    onChange={handleChangeCustom}
                    optionLabelProp="label"
                >
                    {products.length > 0 &&
                        products.map(product => {
                            return (
                                <>
                                    <Option key={product.key} value={product.value} label={product.value}>
                                        <div>
                                            {product.value}
                                        </div>
                                    </Option>
                                </>
                            )
                        })}
                </Select>
                {/* {products.length > 0 && console.log(products.map(product => product.name))} */}

                <Button type="primary" size="small" style={{ margin: "3px" }} onClick={onSubmitCustom}>Save</Button>
            </>
        )
    }

    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Materials" key="1">
                    {selectMaterials()}

                </TabPane>
                <TabPane tab="Custom" key="2">
                    {selectCustom()}
                </TabPane>
            </Tabs>


        </>
    )

}
const mapStateToProps = state => {
    return {
        org_id: state.org_id,
    }
}

export default connect(mapStateToProps)(SetMaterials);