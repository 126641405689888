import React, { useState, useEffect } from "react";
import { Select, PageHeader, Button } from 'antd';
import axios from 'axios';


const AdminUsers = (props) => {

    let [admins, setAdmins] = useState([]);

    useEffect(() => {

        async function getAdmins() {
            axios.get(`/backend/admin_users/`,{})
                .then(res => {
                    let _admins = res.data.map(item => ({ 'key': item.user, 'value': item.username }))

                    setAdmins(_admins);
                })
        }
        if (props.org_id) {
            getAdmins()
        }
    }, [props.org_id]);

    const { Option } = Select;

    function handleChange(values, keys) {
        setAdmins(keys)
        console.log(keys);
    }

    function onSubmit() {
        axios.post(`/backend/admin_users/`, {
            "org_id": props.org_id,
            "users": admins.map(item => (item.key)),
        }, {})
            .then(function (response) {
                console.log(response);
            })
    }
    return (
        <>
            <PageHeader title="Admin Users" />
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select users"
                allowClear
                value={admins.map(admin => (admin.value))}
                // value={console.log(admins)}
                onChange={handleChange}
                optionLabelProp="label"

            >
                {props.users &&
                    [...Array.from(props.users)].map(user => {
                        return (
                            <>
                                <Option key={user.user} value={user.username} label={user.username}>
                                    <div>
                                        {user.username}
                                    </div>
                                </Option>
                            </>
                        )
                    })}
            </Select>
            <Button type="primary" size="small" style={{ margin: "3px" }} onClick={onSubmit}>Save admins</Button>

        </>
    );
};

export default AdminUsers;