import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    username: null,
    user_id: null,
    error: null, 
    loading: false,
    data: null,
    columns: null,
    org: null,
    org_id: null,
}

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        username: action.username,
        user_id: action.user_id,
        error: null,
        loading: false
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        username: null,
        user_id: null,
        org: null,
        org_id: null,
    });
}

// const workOrderSuccess = (state, action) => {
//     return updateObject(state, {
//         workorder_data: action.data,
//         workorder_columns: action.columns,
//     });
// }
const lineItemSuccess = (state, action) => {
    return updateObject(state, {
        lineitem_data: action.data,
        lineitem_columns: action.columns,
    });
}

const orgStart = (state, action) => {
    return updateObject(state, {
        org_id: null,
        org: null,
        org_failed: false,

    });
}
const orgSuccess = (state, action) => {
    return updateObject(state, {
        org_id: action.org_id,
        org: action.org,
        is_admin: action.is_admin,
        org_failed: false,
        custom_items: action.custom_items,
        setup_org: action.setup_org,
        describe_user_on_lineitem: action.describe_user_on_lineitem,
    });
}
const orgFail = (state, action) => {
    return updateObject(state, {
        org_id: null,
        org: null,
        is_admin: null,
        org_failed: true,
    });
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        // case actionTypes.WORKORDER_SUCCESS: return workOrderSuccess(state, action);
        case actionTypes.LINEITEM_SUCCESS: return lineItemSuccess(state, action);
        case actionTypes.ORG_SUCCESS: return orgSuccess(state, action);
        case actionTypes.ORG_START: return orgStart(state, action);
        case actionTypes.ORG_FAIL: return orgFail(state, action);
        default:
            return state;
    }
}

export default reducer;