export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const WORKORDER_SUCCESS = 'WORKORDER_SUCESS';
export const LINEITEM_SUCCESS = 'LINEITEM_SUCCESS';
export const ORG_SUCCESS = 'ORG_SUCCESS';
export const ORG_START = 'ORG_START';
export const ORG_FAIL = 'ORG_FAIL';
export const SET_REDIRECT_COOKIE = 'SET_REDIRECT_COOKIE';


