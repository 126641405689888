import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Popconfirm, Form, Space, Modal, DatePicker, notification } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import axios from 'axios';
import moment from 'moment';


import WorkOrderForm from '../components/WorkOrderForm';
import { setRedirectCookie  } from '../utils/utils';

// import { render } from "@testing-library/react";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
      placement: 'bottomLeft',

    });
  };

const EditableTable = (props) => {
  let [columns, setColumns] = useState([]);
  let [data, setData] = useState([]);
  let [count, setCount] = useState(0);
  let [searchText, setSearchText] = useState('')
  let [searchedColumn, setSearchedColumn] = useState('')
  
  let [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let [visible, setVisible] = useState(false);
  let [loading, setLoading] = useState(false)


  const [workOrderForm] = Form.useForm();
  
  useEffect(() => {
    if(props.token){
      // const _data = (getData(props.token))
      axios.get('/backend/workorders/', {})
      .then(res => {
        //could just set it as a search?
        // setContactFilter([...new Set(res.data.map(x => x.contact))].map(y => ({ "text": y, "value": y })))
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          data[i].key = data[i].pk
          if(data[i].status == null){
            data[i].status = "UNSENT"
          }
        }
        setData(data)
        setCount(data.length);
      })
      .catch();
    }
    setColumns([
    {
      title: "Workorder",
      render: (record) => (
        <React.Fragment>
          {record.identifier} - {record.description}
          <br />
          {record.contact} {record.invoice_number} 
          <br />
          Due: {record.dueDate}
        </React.Fragment>
      ),
      responsive: ["xs"],
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      sorter: (a, b) => a.identifier.length - b.identifier.length,
      sortDirections: ['descend'],
      ...getColumnSearchProps('identifier'),
      responsive: ["sm"],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ...getColumnSearchProps('description'),
      width: "20%",
      editable: true,
      responsive: ["sm"],
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      defaultSortOrder: 'descend',
      // filters: contactFilter,
      ...getColumnSearchProps('contact'),
      // onFilter: (value, record) => record.contact.indexOf(value) === 0,
      sorter: (a, b) => a.contact.length - b.contact.length,
      responsive: ["sm"],
    }, 
    {
      title: 'Invoice',
      dataIndex: 'invoice_number',
      ...getColumnSearchProps('invoice_number'),
      responsive: ["sm"],

    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
      sortDirections: ['descend', 'ascend'],
      responsive: ["sm"],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [{'text':'DRAFT', 'value': 'DRAFT'},  {'text':'AUTHORISED', 'value': 'AUTHORISED'}, {'text':'UNSENT', 'value': 'UNSENT'}],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend'],
      responsive: ["sm"],
    },
    ]);

  }, [props.token, setData, setColumns, selectedRowKeys]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const handleAdd = (values) => {
    console.log(values)

    axios.post('/backend/workorders/', {
      organisation: props.org_id,
      identifier: values.identifier,
      description: values.description,
      contact: values.contact,
      due_date: values.due_date.format("YYYY-MM-DD"),
    }, {})
    .then(res => {
      if (res.status === 201) {
        console.log(res)
        let _data = [...Array.from(data)];
        let newData = {
          key: res.data.pk,
          identifier: res.data.identifier,
          description: res.data.description,
          contact: res.data.contact,
          // invoice_number: values.invoice_number,
          due_date: res.data.due_date,
          status: "UNSENT",
        };
        setData([newData, ..._data, ])
        setCount(count+1)
        workOrderForm.resetFields()
        openNotificationWithIcon('success', 'Workorder renewed successfully!')
      }
    }
    )
    .catch(err => {
      //handle err empty response when backend is down
    console.log(err.response)
    openNotificationWithIcon('error', 'Creating new workorder failed!', 'Try again, if the the issue persists contact an admin.')
    }) 

    // form.resetFields();

  }
  const handleSave = (row) => {
    const newData = [...Array.from(data)]
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    if(row.description !== item.description){
      axios.post('backend/workorders/update/invoice', {
        workorder: row.key,
        description: row.description,
      }, {})
      .then(res => {
        if(res.status === 204){

          newData.splice(index, 1, { ...item, ...row });
          setData(newData)
          setCount(count)
          openNotificationWithIcon('success', 'Workorder description updated!')
        }
      }
      ).catch(err => {
        if (err.response.status === 403) {
            openNotificationWithIcon('error', 'Unable to authenticate with Xero', 'You will be redirected to login to xero.')

            console.log(err.response)
            setRedirectCookie(window.location.href)
            props.history.push("/oauth");
          }
        else{
          console.log(err.response)
          openNotificationWithIcon('error', 'Updating description failed!', 'Try again, if the the issue persists contact an admin.')
        }
        }
      )
    }
  };
  const due_date = () => {
    let now = new Date()
    let due = new Date(now.getFullYear(), now.getMonth() + 1, 20)
    // let dateFormat = 'DD-MM-YYYY';
    // console.log({due_date: moment(due)})
    return {due_date: moment(due)}
    // return moment(`20-${now.getMonth()}-${now.getFullYear()}`, 
    // return moment(`${now.getFullYear()}/, now.getMonth() + 1, 20)
    // return moment('01/01/2015', dateFormatList[0])
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const _columns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });
    const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        visible={visible}
        title="Reset Workorders"
        okText="Reset"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={due_date()}
        >
          <Form.Item name="due_date" label="Due Date" rules={[{ required: true, message: 'Please set a due date',}]} >
            <DatePicker format="DD-MMM-YYYY" />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys)
  };
  const rowSelection = {
    getCheckboxProps: (record) => {
      return {};
    },
    onChange: onSelectChange,
  };
    const onCreate = (values) => {
    console.log('Received values of form: ', values);
    resetWorkorders(values)
    setVisible(false);
  };
    const resetWorkorders = (dueDate) => {

    let _workorders = { "keys": selectedRowKeys, "delete": false, "due_date": dueDate.due_date, }
    axios.post('/backend/workorders/manage/', _workorders, {}).then(res => {
      if (res.status == 200) {
        openNotificationWithIcon('success', 'Workorder renewed successfully!')
        console.log(res);
        setLoading(false)
        // data
        selectedRowKeys.map(key => {
          data[key].invoice_number = "";
          data[key].due_date = dueDate
        })
        setSelectedRowKeys([]);
      }
    })
      .catch(err => {
        //handle err empty response when backend is down
        if (err.response) {
          if (err.response.status !== 200) {
            console.log(err.response)
            openNotificationWithIcon('error', 'Something went wrong!', 'There is an issue with the workorder for the items you are trying to submit.')
          }


          else {
            console.log(err.response)
            setLoading(false)
            openNotificationWithIcon('error', 'Something went wrong!', 'Try again, if the the issue persists contact an admin.')
          }
        }
      })
  }
    const deleteWorkorders = () => {
    console.log(selectedRowKeys)
    let _workorders = { "keys": selectedRowKeys, "delete": true }
    axios.post('/backend/workorders/manage/', _workorders, {})
    .then(res => {
      if (res.status == 200) {
        openNotificationWithIcon('success', 'Workorder deleted successfully!')
        console.log(res);
        setLoading(false)
        // data
        let _data = data
        selectedRowKeys.map(key => {
          let index = _data.indexOf(key)
          _data.splice(index, 1)
        })
        setData(_data)
        setSelectedRowKeys([]);
      }
    })
      .catch(err => {
        //handle err empty response when backend is down
        if (err.response) {
          if (err.response.status) {
            console.log(err.response)
            openNotificationWithIcon('error', 'Something went wrong!', 'There is an issue with the workorder for the items you are trying to submit.')
          }


          else {
            console.log(err.response)
            setLoading(false)
            openNotificationWithIcon('error', 'Something went wrong!', 'Try again, if the the issue persists contact an admin.')
          }
        }
      })
  }
  const hasSelected = selectedRowKeys.length > 0;
  const pagination = {
    showSizeChanger: true,
    pageSizeOptions: ["25", "50", "100"],
    defaultPageSize: 20,
  }
  return (
    <div>          
      <Button type="primary" style={{margin:"2px"}} onClick={() => { setVisible(true); }} disabled={!hasSelected} loading={loading}>
            Reset
      </Button>
      <Button type="primary" style={{margin:"2px"}} onClick={deleteWorkorders} disabled={!hasSelected}>
            Delete
      </Button>
      <WorkOrderForm style={{margin:"2px"}} onSubmit={handleAdd} due_date={due_date()}/>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={data}
        columns={_columns}
        rowSelection={rowSelection}
        pagination={pagination}
      />
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.token,
    org_id: state.org_id,
  }
}

export default connect(mapStateToProps)(EditableTable);
