import React from "react";
import { Input, notification, PageHeader } from 'antd';
import axios from 'axios';
import { UserAddOutlined } from '@ant-design/icons';


const AddUser = (props) => {

    const { Search } = Input;
    const key = 'updatable';

    const openNotificationWithIcon = (type, title, description) => {
        notification[type]({
            message: title,
            description: description,
            placement: 'bottomLeft',

        });
    };

    const onSearch = (value) => {
        // message.loading({ content: 'Loading...', key });
        var data = {"search": value, "org_id": props.org_id}
        axios.post(`/backend/profile_search/`, data,{}).then(res => {
                if (res.status == 200) {
                    openNotificationWithIcon('success', 'User Added!')
                    console.log(res);
                }
            })
            .catch(err => {
                if (err.response.status === 404) {
                    openNotificationWithIcon('warning', 'User not found!')
                } else if (err.response.status === 409) {
                    // client never received a response, or request never left
                    openNotificationWithIcon('warning', 'User is already in an organisation')
                } else if (err.request) {
                    console.log("Unable to connect");
                    openNotificationWithIcon('error', 'Something went wrong!', 'Try again, if the the issue persists contact an admin.')
                } else {
                    // anything else
                    console.log("Something bad happened: " + err)
                    openNotificationWithIcon('error', 'Something went wrong!', 'Try again, if the the issue persists contact an admin.')
                }
            })
    }

    return (
        <>
            <PageHeader title="Add a user" />
            <Search
                placeholder="enter a username"
                allowClear
                onSearch={onSearch}
                prefix={<UserAddOutlined />}
            />


        </>
    );
};

export default AddUser;

// function (response) {

//     setTimeout(() => {
//         message.success({ content: 'Loaded!', key, duration: 2 });
//     }, 0);
//     console.log(response);
// }

// import { Button, notification, Space } from 'antd';

// const openNotificationWithIcon = type => {
//   notification[type]({
//     message: 'Notification Title',
//     description:
//       'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
//   });
// };

// ReactDOM.render(
//   <Space>
//     <Button onClick={() => openNotificationWithIcon('success')}>Success</Button>
//     <Button onClick={() => openNotificationWithIcon('info')}>Info</Button>
//     <Button onClick={() => openNotificationWithIcon('warning')}>Warning</Button>
//     <Button onClick={() => openNotificationWithIcon('error')}>Error</Button>
//   </Space>,
//   mountNode,
// );
