import axios from 'axios';
import * as actionTypes from './actionTypes';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function setAuthorizationToken(token) {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }
  
// export const setRedirectCookie = (redirect_url) => {
//     cookies.set('oauth_redirect', redirect_url, { path: '/', expires: new Date(Date.now() + 2592000) });
//     return {
//         type: actionTypes.SET_REDIRECT_COOKIE
//     }

// }

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, username, user_id) => {
    localStorage.setItem('isAuthenticated', true);
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        username: username,
        user_id: user_id,
    }
}

export const authFail = error => {
    localStorage.removeItem('password');
    localStorage.removeItem('isAuthenticated');
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    cookies.remove('org',{ path: '/' })
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('expirationDate');
    // localStorage.removeItem('username');
    localStorage.removeItem('user_id');
    localStorage.removeItem('org');
    localStorage.removeItem('org_id');
    setAuthorizationToken(null)
    // localStorage.removeItem('password');

    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.post('/rest-auth/login/', {
            username: username,
            password: password
        })
            .then(res => {
                const token = res.data.key;
                const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                localStorage.setItem('token', token);
                setAuthorizationToken(token)
                localStorage.setItem('expirationDate', expirationDate);
                axios.get('/rest-auth/user/', {})
                    .then(res2 => {
                        const username = res2.data.username;
                        const user_id = res2.data.pk;
                        localStorage.setItem('username', username);
                        localStorage.setItem('user_id', user_id);
                        localStorage.setItem('password', password);
                        dispatch(authSuccess(token, username, user_id));
                        dispatch(getOrg());
                        dispatch(checkAuthTimeout(3600));

                    })
            })
            .catch(err => {
                dispatch(authFail(err))
            })
    }
}

export const authSignup = (username, email, password1, password2) => {
    return dispatch => {
        dispatch(authStart());
        axios.post('/rest-auth/registration/', {
            username: username,
            email: email,
            password1: password1,
            password2: password2
        })
            .then(res => {
                const token = res.data.key;
                const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                localStorage.setItem('token', token);
                setAuthorizationToken(token)
                localStorage.setItem('expirationDate', expirationDate);
                axios.get('/rest-auth/user/', {})
                    .then(res2 => {
                        const username = res2.data.username;
                        const user_id = res2.data.pk;
                        localStorage.setItem('username', username);
                        localStorage.setItem('user_id', user_id);
                        dispatch(authSuccess(token, username, user_id));
                        dispatch(getOrg());
                        dispatch(checkAuthTimeout(3600));

                    })
            })
            .catch(err => {
                dispatch(authFail(err))
            })
    }
}

export const authCheckState = () => {
    return dispatch => {
        console.log("rechecking auth status")
        const token = localStorage.getItem('token');
        if (token === undefined) {
            dispatch(logout());
        }else {
            setAuthorizationToken(token)
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(getOrg());
                dispatch(authSuccess(token, localStorage.getItem('username'), localStorage.getItem('user_id')));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}


export const orgStart = () => {
    return {
        type: actionTypes.ORG_START
    }
}
export const orgSuccess = (org, org_id, is_admin, custom_items, describe_user_on_lineitem) => {
    cookies.set('org', org, {path: '/', maxAge: 31536000});
    let setup_org = cookies.get('setup_org')
    // console.log(setup_org)
    return {
        type: actionTypes.ORG_SUCCESS,
        org: org,
        org_id: org_id,
        is_admin: is_admin,
        custom_items: custom_items,
        setup_org: setup_org,
        describe_user_on_lineitem: describe_user_on_lineitem,
    }
}
export const orgFail = () => {
    // cookies.remove('org',{ path: '/' })
    return {
        type: actionTypes.ORG_FAIL,
    }
}

export const checkOrg = () => {
    console.log("checking org")
    const org = localStorage.getItem('org');
    console.log(org)
    


}
export const getOrg = () => {
    return dispatch => {
        // dispatch(orgStart());
        console.log("getting org")
        let user_id = localStorage.getItem('user_id');
        axios.get(`/backend/profile_detail/${user_id}/`, {})
        .then(res => {
                if(res.data.length !== 0 && 'organisation' in res.data[0]){
                    if(res.data[0].organisation === null){
                        dispatch(orgFail())
                    }else{
                        const org_id = res.data[0].organisation
                        const org = res.data[0].org_name
                        const is_admin = res.data[0].is_admin
                        const custom_items = res.data[0].custom_items
                        const describe_user_on_lineitem = res.data[0].describe_user_on_lineitem
                        localStorage.setItem('org_id', org_id);
                        localStorage.setItem('is_admin', is_admin);
                        localStorage.setItem('org', org);
                        console.log(res.data[0])
                        dispatch(orgSuccess(org, org_id, is_admin, custom_items, describe_user_on_lineitem))
                    }
                }else{
                    dispatch(orgFail())
                }
            })
    }
}