import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import * as actions from '../store/actions/auth';
import {
  Form,
  Input,
  Button,
  message,
} from 'antd';
import { UserOutlined, MailOutlined, LockOutlined, ShopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const RegistrationForm = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if(props.user_id){
      console.log("you have created a user")
    }
    if(props.error){
      message.error("Username or email already exists");
    }
  }, [props.error, props.user_id, props.token]);


  const onFinish = (values) => {
    props.onAuth(values.username, values.email, values.password, values.confirm)

    // props.history.push('/');
  };

  return (
    <>
    { props.isAuthenticated ?
      <Redirect to={'/timesheet/create/'} />
      :
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Sign up
        </Button>
         Or <Link to="/login/">Log in</Link>
      </Form.Item>
    </Form>
    }
  </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error, 
    user_id: state.user_id,
    token: state.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password1, password2) => dispatch(actions.authSignup(username, email, password1, password2))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
