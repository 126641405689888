import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
const { Option } = Select;


const WorkOrderForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([
    { label: "Loading ...", value: "" }
  ]);
  const [value] = React.useState();
  const [form] = Form.useForm();

  useEffect(() => {
    getContacts();
  }, [props.token]);

  async function getContacts() {
    axios.get('/backend/contacts/', {})
      .then(res => {
        setItems(
          res.data.map(({ name, pk }) => ({
            label: name,
            value: name,
            key: pk,
          }))
        );
        setLoading(false)
      })
  }
  const onFinish = (values) => {
    console.log('Success:', props.org_id);
    //TODO: need to make sure theres not a workorder with the same name already
    axios.post('/backend/workorders/', {
      organisation: props.org_id,
      identifier: values.identifier,
      description: values.description,
      contact: values.contact,
      due_date: values.due_date.format("YYYY-MM-DD"),
    }, {})
    form.resetFields();
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
    form.resetFields();
  };
  const config = {
    rules: [
      {
        type: 'object',
        required: true,
        message: 'Please select a time!',
      },
    ],
  };
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };

  const formTailLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 12, offset: 4 },
  };

  const onSubmit = (values) => {
    // e.preventDefault();
    props.onSubmit(values);
  }
  return (

    <Form
      // {...formItemLayout}
      form={form}
      name="workorder"
      initialValues={{
        remember: false,
        due_date: props.due_date.due_date
      }}
      // wrapperCol={{ span: 10 }}
      layout="inline"
      // onFinish={onFinish}
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
    >

      <Form.Item
        // label="Work Order"
        name="identifier"
        
        rules={[
          {
            required: true,
            message: 'Please enter a workorder',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        // label="Description"
        name="description"
       
        rules={[
          {
            required: true,
            message: 'Please enter a description',
          },
        ]}
      >
        <Input  placeholder="Description" />
      </Form.Item>

      <Form.Item
        // label="Contact"
        name="contact"
        placeholder="Contact person"
        rules={[
          {
            required: true,
            message: 'Please select a contact',
          },
        ]}>

        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          disabled={loading}
          value={value}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {items && items.map(({ label, value, pk }) => (
            <Option key={pk} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item placeholder="date" name="due_date" {...config}>
        <DatePicker format="DD-MMM-YYYY" />
      </Form.Item>

      <Form.Item
        name="org"
        // value={this.props.org_id}
        hidden="true"
      >
        <Input />
      </Form.Item>
      <Form.Item {...formTailLayout}>
        <Button type="primary" htmlType="submit">
          Add Workorder
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    org_id: state.org_id,
    token: state.token,
  }
}
export default connect(mapStateToProps)(WorkOrderForm);
